<template>
  <div class="mapDiv" :class="{'mapshow': opacityFlag, 'maphidden': !opacityFlag}">
    <div class="close">
      <i @click="closeClick">
        <van-icon name="cross" />
      </i>
    </div>
    <div id="allmap"></div>
  </div>
</template>

<script>
import { BaiDuMap } from '@/utils/map'
import common from '@/utils/common'
export default {
  data () {
    return {
      ak: 'RdXGpvs4GG3OeBG1qKExl0YAtsmMbQ56',
      //ak: 'bPfwzmMGzFUV7456sGctnFBiTtozXGFj',
      map: null,
      newLng: null,
      newLat: null
    }
  },
  props: {
    opacityFlag: false,
    // lng: 0,
    // lat: 0
  },
  mounted () {
    this.$nextTick(() => {
      BaiDuMap(this.ak).then(BMap => {
        this.setMap();

      })
    })
  },
  watch: {
    '$store.state.mapshow.lng'(newval){
      this.newLng = newval
    },
    '$store.state.mapshow.lat'(newval){
        this.newLat = newval
        this.newPoint()
    }
  },
  methods: {
    toGetLocationBaiDu(){
      var geolocation = new BMap.Geolocation();
      // 开启SDK辅助定位
      geolocation.enableSDKLocation();
      geolocation.getCurrentPosition(function(r){
        // if(this.getStatus() == BMAP_STATUS_SUCCESS){
        var mk = new BMap.Marker(r.point);
        // alert('您的位置：'+r.point.lng+','+r.point.lat);
        // 创建地理编码实例, 并配置参数获取乡镇级数据
        var myGeo = new BMap.Geocoder({extensions_town: true});
        // 根据坐标得到地址描述
        myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function(result){
          if (result){
            self.localCity = result.addressComponents.city
            if (self.localCity != '' && self.localCity != undefined){
              common.setItem('localCity',self.localCity)
            }
          }
        });
        // }

      },function(r){
      })
    },
    newPoint() {
      if (this.newLng == 116.403419 && this.newLat == 39.924074){
        return
      }
      if(!this.map){
        let time = setInterval(() => {
          if(this.map){
            clearInterval(time)
          }
          this.getPoint()
        }, 2000);
      }else{
        this.getPoint()
      }
    },
    getPoint() {
      let point = new BMap.Point(this.newLng, this.newLat)
      let geocoder = new BMap.Geocoder()

      geocoder.getLocation(point, (rs) => {
        this.map.clearOverlays();
        var marker = new BMap.Marker(point);  // 创建标注
        this.map.addOverlay(marker);              // 将标注添加到地图中
        this.map.panTo(point);
        this.map.centerAndZoom(point, 13)

        var addComp = rs.addressComponents;
        this.$store.dispatch('mapshow/setgps', addComp.district + addComp.street + addComp.streetNumber)
      })
    },
    setMap () {
      let self = this
      this.map = new BMap.Map('allmap')
      // 初始化地图，设置中心点和显示级别
      this.map.centerAndZoom(new BMap.Point(this.$store.state.mapshow.lng, this.$store.state.mapshow.lat), 11)
      // 开启鼠标滚轮缩放功能，仅对PC上有效
      this.map.enableScrollWheelZoom(true)
      // 将控件（平移缩放控件）添加到地图上
      this.map.addControl(new BMap.NavigationControl())
      // 将控件（平移缩放控件）添加到地图上
      // this.map.addControl(new BMap.MapTypeControl())
      var geoc = new BMap.Geocoder();
      this.map.addEventListener("click", function (e) {
        console.log("您点击了地图。:", e);
        self.$emit('lngchange', e.point.lng)
        self.$emit('latchange', e.point.lat)
        self.$store.dispatch('mapshow/mapClick', false)
        var pt = e.point;
        console.log("pt:", pt);
        geoc.getLocation(pt, function (rs) {
          console.log("地图res:", rs)
          var addComp = rs.addressComponents;
          self.gps = addComp.district + addComp.street + addComp.streetNumber
          self.$emit('gpschange', self.gps)
          self.$store.dispatch('mapshow/setgps', self.gps)
          console.log("hhhh:", addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber);
        });

      })
      // 创建位置检索、周边检索和范围检索
      this.local = new BMap.LocalSearch(this.map, {
        renderOptions: {
          map: this.map
        }
      })
      setTimeout(()=>{
        this.toGetLocationBaiDu();
      },2000)

    },
    closeClick() {
      this.$store.dispatch('mapshow/mapClick', false)
    }
  }
}
</script>

<style lang="less" scoped>
.mapDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background: #808080;
  padding: 10px;
  .close {
    text-align: right;
    margin-bottom: 14px;
    .van-icon {
      background: url("../../assets/img/close.png");
      background-size: 100% 100%;
      width: 24px;
      height: 24px;
    }

  }
}
.mapshow{
  opacity: 1;
  z-index: 100;
}
.maphidden{
  opacity: 0;
  z-index: -1;
}

#allmap {
  overflow: hidden;
  height: 80%;
  width: 100%;
  margin: auto;
}
</style>
